<template>
  <div class="home">
    
    
    <urunler   />
  </div>
 
</template>

<script>
// @ is an alias to /src
import urunler from '@/components/urunler.vue'

export default {
  name: 'HomeView',
  components: {
    urunler,
  },
}
</script>

<style>
.logo{
  height: 200px;
  width:200px;
}
</style>
