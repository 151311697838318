import { createStore } from 'vuex';

export default createStore({
  state: {
    sepet: []
  },
  getters: {
    getSepet(state) {
      return state.sepet;
    },
    toplamTutar(state) {
      return state.sepet.reduce((total, item) => total + item.toplam, 0);
    }
  },
  mutations: {
    setSepet(state, sepet) {
      state.sepet = sepet;
    },
    addItemToSepet(state, item) {
      state.sepet.push(item);
    },
    removeItemFromSepet(state, index) {
      state.sepet.splice(index, 1);
    }
  },
  actions: {
    updateSepet({ commit }, sepet) {
      commit('setSepet', sepet);
    },
    addItem({ commit }, item) {
      commit('addItemToSepet', item);
    },
    removeItem({ commit }, index) {
      commit('removeItemFromSepet', index);
    },

  },
  modules: {}
});