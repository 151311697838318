<template>
  <div class="container">


    <main>
    <div class="row row-cols-1 row-cols-md-3 mb-3 text-center">
      <!--! Standart Menü -->
      <div class="col">
        <div class="card mb-4 rounded-3 shadow-sm border-success ">
          <div class="card-header py-3">
            <h4 class="my-0 fw-normal">Standart Menü <br>(Kahvaltı + Öğle + Akşam)</h4>
          </div>
          <div class="card-body">
            <h1 class="card-title pricing-card-title">
              <button type="button" class="btn btn-secondary btn-sm" data-bs-toggle="modal" data-bs-target="#exampleModal">
              <i>Örnek haftalık menü için tıklayınız.</i>
              </button>
            </h1>
            <ul class="list-unstyled mt-3 mb-4">
              <li><strong>Her öğün 1 porsiyon</strong></li>
              <li><small class="text-muted">(İstemediğiniz malzemeyi lütfen sepette belirtin.)</small></li>
            </ul>
            <div class="row">
              <div class="col-6">Kişi Sayısı</div>
              <div class="col-6">Gün Sayısı</div>

            </div>

            <!-- İçerik -->
            <div class="row">
              <div class="col-6">
                <div class="input-group">
                  <button class="btn btn-outline-danger" type="button" @click="azalt1">-</button>
                  <input type="text" class="form-control text-center bg-white" :value="kisi1" readonly>
                  <button class="btn btn-outline-success" type="button" @click="arttir1">+</button>
                </div>
              </div>
              <div class="col-6">
                <select v-model="selectedmenu1gun" class="form-select text-center" aria-label="Default select example">
                  
                  <option v-for="menu1gun in menu1gunler" :key="menu1gun" :value="menu1gun">
                    {{ menu1gun }}
                  </option>
                </select>
              </div>
              <small class="text-muted">En az 7 gün seçilebilir.(1 gün = ₺ 250)</small>
            </div>
            <button type="button" class="w-100 btn btn-lg btn-success" @click="sepetekle1">Sepete Ekle (₺ {{ toplam1 }})</button>
          </div>
        </div>
      </div>


      <!--! Büyük Menü -->
      <div class="col ">
        <div class="card mb-4 rounded-3  shadow-sm border-success">
          <div class="card-header py-3 "  >
            <h4 class="my-0 fw-normal "><strong></strong>Büyük Menü <br>(Kahvaltı + Öğle + Akşam)</h4>
          </div>
          <div class="card-body">
            <h1 class="card-title pricing-card-title">
              <button type="button" class="btn btn-secondary btn-sm" data-bs-toggle="modal" data-bs-target="#exampleModal2">
              <i>Örnek haftalık menü için tıklayınız.</i>
              </button>
            </h1>
            <ul class="list-unstyled mt-3 mb-4">
              <li><strong>Her öğün 1.5 porsiyon</strong></li>
              <li><small class="text-muted">(İstemediğiniz malzemeyi lütfen sepette belirtin.)</small></li>
            </ul>
            <div class="row">
              <div class="col-6">Kişi Sayısı</div>
              <div class="col-6">Gün Sayısı</div>

            </div>

            <!-- İçerik -->
            <div class="row">
              <div class="col-6">
                <div class="input-group">
                  <button class="btn btn-outline-danger" type="button" @click="azalt2">-</button>
                  <input type="text" class="form-control text-center bg-white" :value="kisi2" readonly>
                  <button class="btn btn-outline-success" type="button" @click="arttir2">+</button>
                </div>
              </div>
              <div class="col-6">
                <select v-model="selectedmenu2gun" class="form-select text-center" aria-label="Default select example">
                  
                  <option v-for="menu2gun in menu2gunler" :key="menu2gun" :value="menu2gun">
                    {{ menu2gun }}
                  </option>
                </select>
              </div>
              <small class="text-muted">En az 7 gün seçilebilir.(1 gün = ₺ 300)</small>
            </div>
            <button type="button" class="w-100 btn btn-lg btn-success"  @click="sepetekle2">Sepete Ekle (₺ {{ toplam2 }})</button>
          </div>
        </div>

      </div>

      <!--! Protein Menü -->
      <div class="col">
        <div class="card mb-4 rounded-3 shadow-sm border-success">
          <div class="card-header py-3 ">
            <h4 class="my-0 fw-normal">Protein Menü <br>(Kahvaltı + Öğle + Akşam)</h4>
          </div>
          <div class="card-body">
            <h1 class="card-title pricing-card-title">
              <button type="button" class="btn btn-secondary btn-sm" data-bs-toggle="modal" data-bs-target="#exampleModal3">
              Örnek haftalık menü için tıklayınız.
              </button>
            </h1>
            <ul class="list-unstyled mt-3 mb-4">
              <li><strong>Protein ve lif ağırlıklı menüler</strong></li>
              <li><small class="text-muted">(İstemediğiniz malzemeyi lütfen sepette belirtin.)</small></li>
            </ul>
            <div class="row">
              <div class="col-6">Kişi Sayısı</div>
              <div class="col-6">Gün Sayısı</div>

            </div>

            <!-- İçerik -->
            <div class="row">
              <div class="col-6">
                <div class="input-group">
                  <button class="btn btn-outline-danger" type="button" @click="azalt3">-</button>
                  <input type="text" class="form-control text-center bg-white" :value="kisi3" readonly>
                  <button class="btn btn-outline-success" type="button" @click="arttir3">+</button>
                </div>
              </div>
              <div class="col-6">
                <select v-model="selectedmenu3gun" class="form-select text-center" aria-label="Default select example">
                  
                  <option v-for="menu3gun in menu3gunler" :key="menu3gun" :value="menu3gun">
                    {{ menu3gun }}
                  </option>
                </select>
              </div>
              <small class="text-muted">En az 7 gün seçilebilir.(1 gün = ₺ 350)</small>
            </div>
            <button type="button" class="w-100 btn btn-lg btn-success" @click="sepetekle3">Sepete Ekle (₺ {{ toplam3 }})</button>
          </div>
        </div>
      </div>
    </div>
    </main>

      <!--! Modal1 (Standart) -->
      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel"><strong>Örnek Standart Menü</strong></h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              Pazartesi-salı
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Kapat</button>
            </div>
          </div>
        </div>
      </div>
      

    <!--! Modal2 (Büyük) -->
    <div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel"><strong>Örnek Büyük Menü</strong></h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            asdsadsa
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Kapat</button>
          </div>
        </div>
      </div>
    </div>
    
    <!--! Modal3 (Sporcu menü) -->
    <div class="modal fade" id="exampleModal3" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel"><strong>Örnek Protein Menü</strong></h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            213213123
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Kapat</button>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>

import { mapActions } from 'vuex';


export default {
  
  name: 'urunler',
  props: {
    sepet: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      menu1gunler: [],
      menu2gunler: [],
      menu3gunler: [],
      kisi1:1,
      kisi2:1,
      kisi3:1,
      toplam1:0,
      toplam2:0,
      toplam3:0,
      selectedmenu1gun:'7',
      selectedmenu2gun:'7',
      selectedmenu3gun:'7',
      sepet:[]
    };
  },
  computed: {
    toplam1() {
      this.toplam1=this.kisi1 * this.selectedmenu1gun * 250;
      return this.kisi1 * this.selectedmenu1gun * 250;
    },
    toplam2() {
      this.toplam2=this.kisi2 * this.selectedmenu2gun * 300;
      return this.kisi2 * this.selectedmenu2gun * 300;
    },
    toplam3() {
      this.toplam3=this.kisi3 * this.selectedmenu3gun * 350;
      return this.kisi3 * this.selectedmenu3gun * 350;
    }
  },
  methods: {
    ...mapActions(['addItem']),
    sepetekle1() {
      if (this.selectedmenu1gun >= 7) {
        const urun = {
          kisiSayisi: this.kisi1,
          gunSayisi: this.selectedmenu1gun,
          paket:"Ekonomik Menü (3 Ana Öğün)",
          toplam: this.toplam1
        };
      
        this.addItem(urun);
        alert('Sepete Eklendi');
      } else {
        alert('Sepete Eklenemedi');
      }
    },
      sepetekle2() {
      if (this.selectedmenu2gun >= 7) {
        const urun = {
          kisiSayisi: this.kisi2,
          gunSayisi: this.selectedmenu2gun,
          paket:"Normal Menü (3 Ana Öğün)",
          toplam: this.toplam2,
        };
        this.addItem(urun);
        
        alert('Sepete Eklendi');
      } else {
        alert('Sepete Eklenemedi');
      }
    },

    sepetekle3() {
      if (this.selectedmenu3gun >= 7) {
        
        const urun = {
          kisiSayisi: this.kisi3,
          gunSayisi: this.selectedmenu3gun,
          paket:"Büyük Menü (3 Ana Öğün)",
          toplam: this.toplam3,
        };
        this.addItem(urun);
       
        alert('Sepete Eklendi');
      } else {
        alert('Sepete Eklenemedi');
      }
    },

    //kişi sayıları

    //menü1
    arttir1() {
      this.kisi1++;
    },
    azalt1() {
      if (this.kisi1 > 1) {
        this.kisi1--;
      }
    },
    //menü2
    arttir2() {
      this.kisi2++;
    },
    azalt2() {
      if (this.kisi2 > 1) {
        this.kisi2--;
      }
    },

    //menü3
    arttir3() {
      this.kisi3++;
    },
    azalt3() {
      if (this.kisi3 > 1) {
        this.kisi3--;
      }
    }
  },

  created() {
    for (let i = 7; i <= 60; i++) {
      this.menu1gunler.push(i);
      this.menu2gunler.push(i);
      this.menu3gunler.push(i);
    }
  },
  

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
