<template>
  <div class="container">

    <nav class="navbar fixed-top navbar-expand-lg navbar-light bg-white border-bottom">
      <div class="container-fluid">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-center" id="navbarNavAltMarkup">
          <div class="navbar-nav m-4 ">
            <router-link to="/" class=" px-2 ">
              <img class="headerlogo" src="./assets/yemekleugrasma.png">
            </router-link>
            <router-link to="/" class=" px-2 ">Menüler</router-link>
            <router-link to="/sss" class="px-2 ">S.S.S.</router-link>
            <router-link to="/hakkimizda" class="px-2 ">Hakkımızda</router-link>
            <router-link to="/iletisim" class=" px-2 ">İletişim</router-link>
          </div>
        </div>
        
        <button style="background-color: #FF8000; border: none; border-radius: 10px;" class="btn btn-secondary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
        <svg style="margin-right: 5px;" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-cart-check-fill" viewBox="0 0 20 20">
          <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0m7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-1.646-7.646-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L8 8.293l2.646-2.647a.5.5 0 0 1 .708.708"/>
        </svg>
        <span style="font-size: 100%;">{{ this.sepet.length }} </span>

        </button>

      </div>
    </nav>
    <div style="height: 100px;"></div>
    <router-view/>
  </div>
  

   <!--! Sepet buttonu -->
   <button class="sepet" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
    <svg style="margin-right: 5px;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cart-check-fill" viewBox="0 0 16 16">
      <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0m7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-1.646-7.646-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L8 8.293l2.646-2.647a.5.5 0 0 1 .708.708"/>
    </svg>
    <span style="font-size: 100%;">{{ this.sepet.length }} Ürün</span>
    <div  style="height: 30px; right: 20px; left: 20px; font-weight: bold; background-color: white; color: black; border-radius: 10px;">
      <p v-if="sepet.length > 0"> ₺{{ toplamTutar }}</p>
      <p v-else>₺0</p>
    </div>
    </button>

    <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
    <div class="offcanvas-header">
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-cart-check-fill" viewBox="0 0 16 16">
      <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0m7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-1.646-7.646-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L8 8.293l2.646-2.647a.5.5 0 0 1 .708.708"/>
    </svg>
    <h5 id="offcanvasRightLabel"><strong></strong>Sepet</h5>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">

    <table class="table">
      <tbody>
        <tr>
          <td><strong>Kişi-Gün</strong></td>
          <td><strong>Menü</strong></td>
          <td><strong>Fiyat</strong></td>
          <td></td>
        </tr>
      </tbody>
      <tbody >
        <tr v-for="(item, index) in this.sepet" :key="index">
          <td>{{ item.kisiSayisi }} Kişi - {{ item.gunSayisi }} Gün</td>
          <td>{{ item.paket }}</td>
          <td>₺{{ item.toplam }}</td>
          <td >
            <button style="text-align:center;" type="button" class="btn" @click="removeItem(index)">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"/>
              </svg>
            </button>
          </td>
        </tr>
      </tbody>

      <tbody>
        <tr v-if="sepet.length > 0">
          <td></td>
          <td><strong>Toplam Tutar</strong></td>
          <td v-if="sepet.length > 0"><strong>₺{{ toplamTutar }}</strong></td>
          <td v-else><strong>₺0</strong></td>
        </tr>
      </tbody>

    </table>
    <div class="input-group">
      <span class="input-group-text bg-warning">Not</span>
      <textarea class="form-control " aria-label="With textarea" placeholder="Lütfen alerjiniz olan veya yemediğiniz ürün varsa belirtin."></textarea>
    </div>
    <br>
    <div>
      <router-link to="/odeme" class=" px-2 ">
    <button v-if="sepet.length > 0" style="float:right" type="button" class="btn btn-success btn">Ödeme Yap</button>
    </router-link>

    <button v-if="sepet.length < 1" style="float:right" type="button" class="btn btn-secondary btn">Ödeme Yap</button>
    </div>

    </div>
    </div>
    <!--! WhatsApp ikonu -->
    <a
    href="https://wa.me/905522043539"
    target="_blank"
    class="whatsapp-icon"
    aria-label="Chat with us on WhatsApp"
    >

    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
    <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232"/>
    </svg>
    </a>
 

    <!--! footer -->
  <div class="container">
  <footer class="py-3 my-4">
    <ul class="nav justify-content-center pb-3 mb-3">
      <li class="nav-item">
        <a href="#" class="nav-link px-2 text-muted"><img class="footerlogo" src="./assets/yemekleugrasma.png"></a>
      </li>
    </ul>
    <ul class="nav justify-content-center border-bottom pb-3 mb-3">
      <li class="nav-item"><router-link to="/" class="nav-link px-2 ">Menüler</router-link></li>
      <li class="nav-item"><router-link to="/sss" class="nav-link px-2 ">S.S.S.</router-link></li>
      <li class="nav-item"><router-link to="/hakkimizda" class="nav-link px-2 ">Hakkımızda</router-link></li>
      <li class="nav-item"><router-link to="/iletisim" class="nav-link px-2">İletişim</router-link></li>
    </ul>
    <p class="text-center text-muted">&copy; Copyright 2024 | Tüm Hakları Saklıdır | <a class="link" href="#">yemekleugrasma.com</a></p>
  </footer>
</div>
<urunler/>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'odeme',
  computed: {
    ...mapGetters(['getSepet', 'toplamTutar']),
    sepet() {
      return this.getSepet;
    }
  },
  methods: {
    ...mapActions(['removeItem'])
  }
};
</script>

<style>
.nav-link{
  color:#0000009d;
}
.nav-link:hover{
  color:black;
  font-weight: bold;
}
.headerlogo{
    width: 300px; /* Logonun genişliği */
    height: 50px; /* Logonun yüksekliği */

    position: absolute;
    left: 100px;
    top: 20px;
    border: none ;
    text-decoration: none;
    border-width: 0;
    border: 0;

}
.footerlogo{

  width: 300px;
  height: 50px;
}
.link{
  text-decoration: none;
  color: #FF8000;
}
.link:hover{
  text-decoration: none;
  color: #FF8000;
  font-weight: bold;
}
table{
  font-size: 13px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
 
}

nav a {

  color: #0000009d;
  text-decoration: none;
}

nav a:hover {
  font-weight: bold;
  color: #000000f8;
  text-decoration: none;
}
nav a.router-link-exact-active {
  color: #000000f8;
}
/* WhatsApp ikonu için stil */
.whatsapp-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #25D366;
  color: white;
  border-radius: 50%;
  padding: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  text-align: center;
  transition: background-color 0.3s;
}

.whatsapp-icon:hover {
  background-color: #128C7E;
  color: white;
  text-decoration: none;
}

.sepet{
  position: fixed;
  bottom: 400px;
  right: 20px;
  height: 80px;
  width: 100px;
  box-shadow: -5px 5px 10px rgba(163, 0, 0, 0.363);
  background-color:#FF8000 ;
  border: none;
  color: white;
  padding: 15px;
  z-index: 1001;
  text-align: center;
  transition: background-color 0.3s;
  border-radius: 20px 0px 0px 20px;
}
.sepet:hover{
  background-color: green;
  box-shadow: -5px 5px 10px rgba(2, 241, 42, 0.363);
}
</style>
